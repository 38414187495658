const Blank = () => {
  return (
    <>
      <div className="header">
        Contact
      </div>
      <hr />
      <p>
        Get in touch via <a href="mailto:&#108;uc%69%65n%72a&#101;%67en%74il%40&#103;%6da%69&#108;&#46;%63o%6d?subject=Hello%20Lucien!">lucienraegentil at gmail dot com</a>. I tend to reply within a few days.
      </p>
    </>
  )
}


export default Blank;