
const ProjectList = ({ projects }) => {

  const ProjectLink = ({ name, link }) => {
    return <div>→ <a href={ link }>{ name }</a> {  }</div>;
  }

  return (
    <div className="projects">
      {projects.map( (p, i) => (
        <div className="left-right" key={ i }>
          <div>
            <a href={ p.links[Object.keys(p.links)[0]] } className="a-no-underline">
              <img className="project-img" src={"/images/thumbs/" + p.thumbnail }  alt="" />
            </a>
          </div>
          <div>
            <div className="project-title">{ p.name }</div>
            <div className="project-description">{ p.description }</div>
            <div className="project-links">
              { Object.keys(p.links).map( (key, i) => <ProjectLink name={ key } link={ p.links[key] } key={ i } /> ) }
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}


export default ProjectList;