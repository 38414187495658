import { Link } from "react-router-dom";
import ProjectList from "../components/ProjectList";

const projects = require('../data/projects.json');


const Music = () => {
  return (
    <div className="layout">

      {/* Information */}

      <div>
        <div className="header">
          <Link to="/">Lucien Rae</Link>
        </div>
        <hr />
        <div className="p">
          <div className="left-right-always">
            <div><img className="profile-img" src="/images/me_armchair_small.png" alt="" /></div>
            <div>A collection of my recent works, mainly my contemporary solo piano compositions, with some personal notes.</div>
          </div>
          <p>
            → <a href="https://www.instagram.com/lucienmakesmusic/">Instagram</a>
          </p>
          <p>
            → <Link to="/contact">Contact</Link>
          </p>
        </div>
      </div>

      {/* Projects */}

      <div>
        <div className="header">
          Recent works
        </div>
        <hr />
        <ProjectList projects={ projects.music } />
        <hr />
        <div className="p">
          I have many eras of pre-"Lucien Rae" work that I may add in here for nostalgic/archival purposes.
        </div>
      </div>
    </div>
  )  
}



export default Music;