import { Link } from "react-router-dom";


const Blank = () => {
  return (
    <div>
      Sorry! This page has been retired or is under maintenance. <Link to="/">Go back home</Link>.
    </div>
  )
}


export default Blank;