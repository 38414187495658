import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Style
import './style/index.scss';

// Components
import Home from "./pages/Home";
import Music from "./pages/Music";
import Contact from "./pages/Contact";
import Blank from './pages/Blank';


const App = () => {
  return (
    <div className="app">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="music" element={<Music />} />
          <Route path="contact" element={<Contact />} />
          <Route path="blank" element={<Blank />} />
        </Routes>
      </BrowserRouter>
      <div className="footer">
        &copy; Lucien Rae Gentil { new Date().getFullYear() }
      </div>
    </div>
  )
}




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

